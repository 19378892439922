import classNames from 'classnames';
import React from 'react';
import { Decoration_Type, Node_Type } from 'ricos-schema';
import { addAnchorTagToUrl, anchorScroll, normalizeHref } from 'wix-rich-content-common';

var DEFAULTS = {
  config: {
    linkTypes: {
      anchor: true
    }
  }
};

var LINK_TYPE = "LINK";

var styles = {"fontElementMap":"_7qDUH","link":"hxVNc","linkInEditor":"G9yFG","linkInViewer":"_6IKdy","toolbarUrlContainer":"HI-WG","toolbarUrl":"_5dkfA","toolbarUrlAnchor":"PLAkt"};

var WEB_LINK = "WebLink";
var ANCHOR_LINK = "AnchorLink";

var decorator$1 = function decorator(anchorDecoration, element) {
  var anchor = anchorDecoration.anchorData.anchor;
  return /* @__PURE__ */React.createElement("a", {
    key: "anchor-" + (element == null ? void 0 : element.key),
    target: "_self",
    href: "#viewer-" + anchor,
    className: classNames(styles.link, styles.linkInViewer),
    onClick: function onClick(e) {
      e.stopPropagation();
      e.preventDefault();
      var anchorString = "viewer-" + anchor;
      var element2 = document.getElementById(anchorString);
      addAnchorTagToUrl(anchorString);
      anchorScroll(element2);
    },
    "data-hook": ANCHOR_LINK
  }, element);
};
var AnchorDecorator = {
  rule: function rule(node) {
    var _a, _b;
    return !!((_b = (_a = node.textData) == null ? void 0 : _a.decorations) == null ? void 0 : _b.some(function (_ref) {
      var type = _ref.type;
      return type === Decoration_Type.ANCHOR;
    }));
  },
  decorate: function decorate(_ref2) {
    var element = _ref2.element,
      node = _ref2.node;
    var _a, _b;
    var anchorDecoration = (_b = (_a = node.textData) == null ? void 0 : _a.decorations) == null ? void 0 : _b.find(function (_ref3) {
      var type = _ref3.type;
      return type === Decoration_Type.ANCHOR;
    });
    return decorator$1(anchorDecoration, element);
  },
  priority: 1e3
};

var decorator = function decorator(element, linkDecoration, contextualData, node) {
  var _a;
  var _linkDecoration$linkD = linkDecoration.linkData.link,
    _linkDecoration$linkD2 = _linkDecoration$linkD === void 0 ? {} : _linkDecoration$linkD,
    url = _linkDecoration$linkD2.url,
    _linkDecoration$linkD3 = _linkDecoration$linkD2.target,
    target = _linkDecoration$linkD3 === void 0 ? contextualData.anchorTarget : _linkDecoration$linkD3,
    _linkDecoration$linkD4 = _linkDecoration$linkD2.rel,
    rel = _linkDecoration$linkD4 === void 0 ? {} : _linkDecoration$linkD4;
  var relValue = ["noopener", rel.nofollow ? "nofollow" : null, rel.sponsored ? "sponsored" : null, rel.ugc ? "ugc" : null, rel.noreferrer ? "noreferrer" : null].filter(Boolean).join(" ");
  var onClick = function onClick() {
    var _a2, _b;
    return (_b = (_a2 = contextualData.helpers).onViewerAction) == null ? void 0 : _b.call(_a2, LINK_TYPE, "Click", "", node.id, {
      type: Node_Type.TEXT,
      textData: node.textData
    });
  };
  return /* @__PURE__ */React.createElement("a", {
    key: "link=" + element.key,
    target: "_" + ((_a = target == null ? void 0 : target.toLowerCase) == null ? void 0 : _a.call(target)),
    href: url && normalizeHref(url),
    rel: relValue,
    className: classNames(styles.link, styles.linkInViewer),
    "data-hook": WEB_LINK,
    onClick: onClick
  }, element);
};
var LinkDecorator = {
  rule: function rule(node) {
    var _a, _b;
    return !!((_b = (_a = node.textData) == null ? void 0 : _a.decorations) == null ? void 0 : _b.some(function (_ref) {
      var type = _ref.type;
      return type === Decoration_Type.LINK;
    }));
  },
  decorate: function decorate(_ref2) {
    var element = _ref2.element,
      node = _ref2.node,
      context = _ref2.context;
    var _a, _b;
    var linkDecoration = (_b = (_a = node.textData) == null ? void 0 : _a.decorations) == null ? void 0 : _b.find(function (_ref3) {
      var type = _ref3.type;
      return type === Decoration_Type.LINK;
    });
    return decorator(element, linkDecoration, context, node);
  },
  priority: 1e3
};

var nodeViewDecorators = [LinkDecorator, AnchorDecorator];

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var pluginLink = function pluginLink(config) {
  return {
    config: __spreadValues(__spreadValues({}, DEFAULTS.config), config),
    type: LINK_TYPE,
    nodeViewDecorators: nodeViewDecorators
  };
};

export { LINK_TYPE, pluginLink };

import 'wix-rich-content-plugin-link/dist/loadable/viewer/es/styles.viewer.min.global.css'